// /* eslint-disable no-unused-vars */
// import React, { useEffect, useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import axiosInstance from '../Helper/AxiosInstance';
// import { toast } from 'react-toastify';
// import { FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
// import { TailSpin } from 'react-loader-spinner';
// import { setLinkLoggedIn } from '../Redux/action/dataslice';
// import { useDispatch } from 'react-redux';

// const LinkedInCallback = () => {
//     const location = useLocation();
//     const dispatch = useDispatch();
//     let token = sessionStorage.getItem("token");
//     const [code, setCode] = useState('');
//     const [pages, setPages] = useState([]);
//     const [linkedInUserName, setLinkedInUserName] = useState('');
//     const [linkedInProfilePic, setLinkedInProfilePic] = useState('');
//     const [linkedInFollowersCount, setLinkedInFollowersCount] = useState('');
//     const [linkedinPageName, setLinkedInPageName] = useState('')
//     const [activeSelection, setActiveSelection] = useState(null);
//     const [isLoading, setIsLoading] = useState(false);
//     const [isPageConnecting, setIsPageConnecting] = useState(false);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const urlParams = new URLSearchParams(location.search);
//         const codeParam = urlParams.get('code');
//         if (codeParam) {
//             setCode(codeParam);
//             console.log('Authorization code:', codeParam);
//         } else {
//             console.error('Authorization code is missing from the URL parameters.');
//         }
//     }, [location]);

//     const handleLinkedInCallback = async (code, type) => {
//         try {
//             setIsLoading(true);
//             console.log('Sending request with code:', code, 'and type:', type);
//             const response = await axiosInstance.post(`/quantum-share/linkedin/callback/success?code=${code}&type=${type}`, { code, type }, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     'Accept': 'application/json',
//                 }
//             });
//             console.log('Full response:', response);
//             const responseData = response.data;
//             console.log('Response data:', responseData);
//             if (responseData) {
//                 if (type === 'profile') {
//                     if (responseData.data) {
//                         const { linkedInUserName, linkedInProfilePic } = responseData.data;
//                         setLinkedInUserName(linkedInUserName);
//                         setLinkedInProfilePic(linkedInProfilePic);
//                         console.log('LinkedIn Profile Connect:' , response)
//                         dispatch(setLinkLoggedIn(true))
//                         navigate("/social-integration");
//                         toast.success("Connected to LinkedIn Profile!");
//                     } else {
//                         console.error('linkedInUserName is missing in the response data.');
//                     }
//                 } else if (type === 'page') {
//                     const pagesData = responseData.data;
//                     console.log('Pages data:', pagesData);

//                     if (pagesData && Array.isArray(pagesData)) {
//                         setPages(pagesData);
//                     } else {
//                         console.error('Pages data is missing or invalid in the response.');
//                         setPages([]);
//                     }
//                 }
//             } else {
//                 console.error('Response data is missing.');
//             }
//         } catch (error) {
//             console.error('Error sending token to backend:', error);
//             toast.error("Error connecting to LinkedIn. Please try again later.");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleProfileClick = () => {
//         setActiveSelection('profile');
//         if (code) {
//             handleLinkedInCallback(code, 'profile');
//         } else {
//             console.error('Authorization code is missing.');
//         }
//     };

//     const handlePageClick = () => {
//         setActiveSelection('page');
//         if (code) {
//             handleLinkedInCallback(code, 'page');
//         } else {
//             console.error('Authorization code is missing.');
//         }
//     };

//     const handlePageSelect = async (page) => {
//         try {
//             setIsPageConnecting(true);
//             console.log('Selected page:', page);
//             const response = await axiosInstance.post('/quantum-share/linkedIn/selected/page', page, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     'Content-Type': 'application/json',
//                 }
//             });
//             console.log('Save page response:', response);
//             if (response.status === 200) {
//                 const { linkedInUserName, linkedInProfilePic, linkedInFollowersCount } = response.data.data;
//                 console.log(linkedInUserName);
//                 setLinkedInUserName(linkedInUserName);
//                 setLinkedInProfilePic(linkedInProfilePic);
//                 setLinkedInFollowersCount(linkedInFollowersCount);
//                 console.log('LinkedIn Page Connect:' , response)
//                 dispatch(setLinkLoggedIn(true))
//                 navigate("/social-integration");
//                 toast.success('Connected to LinkedIn Page!');
//             } else {
//                 toast.error("Failed to save the selected page.");
//             }
//         } catch (response) {
//             toast.error("Error saving the selected page. Please try again later.");
//         } finally {
//             setIsPageConnecting(false);
//         }
//     };

//     return (
//         <section id='callbacks'>
//             <article>
//                 {isLoading ? (
//                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                         <TailSpin color="#d3040c" height={50} width={50} />
//                     </div>
//                 ) : (
//                     <>
//                         {activeSelection !== 'page' ? (
//                             <div>
//                                 <h2 style={{ textAlign: 'center', marginTop: '1rem', color: '#d3040c' }}>LinkedIn: what do you want to set up?</h2>
//                                 <div style={{ marginLeft: '4.5rem', marginTop: '2rem' }}>
//                                     <div onClick={handleProfileClick} style={{ margin: '1rem', display: 'flex', gap: '10px' }}>
//                                         {activeSelection === 'profile' ? <FaRegCircleDot style={{ color: 'blue', fontSize: '1.3rem' }} /> : <FaRegCircle style={{ fontSize: '1.3rem' }} />}
//                                         <h3>LinkedIn Personal Profile</h3>
//                                     </div>
//                                     <div onClick={handlePageClick} style={{ margin: '1rem', display: 'flex', gap: '10px' }}>
//                                         {activeSelection === 'page' ? <FaRegCircleDot style={{ color: 'blue', fontSize: '1.3rem' }} /> : <FaRegCircle style={{ fontSize: '1.3rem' }} />}
//                                         <h3>LinkedIn Company Pages</h3>
//                                     </div>
//                                 </div>
//                             </div>
//                         ) : null}
//                                {Array.isArray(pages) && pages.length > 0 && activeSelection === 'page' && !isPageConnecting ? (
//                             <div>
//                                 <h3 style={{ color: '#d3040c', textAlign: 'center' }}>Your LinkedIn Associated Pages :</h3>
//                                 <ul>
//                                     {pages.map((page) => (
//                                         <li key={page.linkedinPageURN} style={{ marginLeft: '7.5rem' }} className='list'>
//                                             <div onClick={() => handlePageSelect(page)} style={{ margin: '1rem', display: 'flex', gap: '10px', alignItems: 'center' }}>
//                                                 {/* Display LinkedIn Page Image */}
//                                                 <img 
//                                                     src={page.linkedinPageImage || 'https://via.placeholder.com/50'} 
//                                                     alt={page.linkedinPageName} 
//                                                     style={{ width: '50px', height: '50px', borderRadius: '50%' }} 
//                                                 />
//                                                 <h3>{page.linkedinPageName}</h3>
//                                             </div>
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         ) : null}
//                         {isPageConnecting && (
//                             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                 <TailSpin color="#d3040c" height={50} width={50} />
//                             </div>
//                         )}
//                         {linkedinPageName && (
//                             <div>
//                                 <h3>Selected Page :</h3>
//                                 <p>{linkedinPageName}</p>
//                             </div>
//                         )}
//                     </>
//                 )}
//             </article>
//         </section>
//     );
// };

// export default LinkedInCallback;



import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../Helper/AxiosInstance';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import QS from '../Assets/QS.webp'
import { setLinkedInProfile } from '../Redux/action/pageUrlsSlice';
import { setLinkName } from '../Redux/action/NameSlice';
import { setLinkLoggedIn } from '../Redux/action/loginStatusSilce';

const LinkedInCallback = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    let token = sessionStorage.getItem("token");
    const [code, setCode] = useState('');
    const [pages, setPages] = useState([]);
    const [linkedInUserName, setLinkedInUserName] = useState('');
    const [linkedInProfilePic, setLinkedInProfilePic] = useState('');
    const [linkedInFollowersCount, setLinkedInFollowersCount] = useState('');
    const [activeSelection, setActiveSelection] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageConnecting, setIsPageConnecting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const codeParam = urlParams.get('code');
        if (codeParam) {
            setCode(codeParam);
            handleLinkedInCallback(codeParam); 
        } else {
            console.error('Authorization code is missing from the URL parameters.');
        }
    }, [location]);

    const handleLinkedInCallback = async (code) => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.post(`/quantum-share/linkedin/callback/success?code=${code}`, { code }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept': 'application/json',
                }
            });

            const responseData = response.data;
            if (responseData && responseData.data) {
                const { linkedInPages, linkedInProfile } = responseData.data;
                if (linkedInProfile) {
                    setLinkedInUserName(linkedInProfile.name);
                    setLinkedInProfilePic(linkedInProfile.profile_image);
                }
                if (linkedInPages && Array.isArray(linkedInPages)) {
                    setPages(linkedInPages);
                }
            } else {
                console.error('Invalid response from the backend');
            }
        } catch (error) {
            console.error('Error fetching data from backend:', error);
            toast.error("Error connecting to LinkedIn. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelection = async (type, data) => {
        try {
            setIsPageConnecting(true);
            const response = await axiosInstance.post(`/quantum-share/linkedIn/selected/page?type=${type}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200) {
                const { linkedInUserName, linkedInProfilePic, linkedInFollowersCount } = response.data.data;
                setLinkedInUserName(linkedInUserName);
                dispatch(setLinkName(linkedInUserName))
                setLinkedInProfilePic(linkedInProfilePic);
                dispatch(setLinkedInProfile(linkedInProfilePic))
                setLinkedInFollowersCount(linkedInFollowersCount);
                dispatch(setLinkLoggedIn(true))
                navigate("/social-integration");
                toast.success(`Connected to LinkedIn ${type === 'profile' ? 'Profile' : 'Page'}!`);
            } else {
                toast.error(`Failed to connect to LinkedIn ${type === 'profile' ? 'Profile' : 'Page'}`);
            }
        } catch (error) {
            console.error('Error saving the selected page/profile:', error);
            toast.error("Error saving the selected page/profile. Please try again later.");
        } finally {
            setIsPageConnecting(false);
        }
    };

    const handleProfileClick = () => {
        setActiveSelection('profile');
        if (linkedInUserName && linkedInProfilePic) {
            handleSelection('profile', {
                urn: "",  
                name: linkedInUserName,
                profile_image: linkedInProfilePic,
                accessToken: "",
            });
        }
    };

    const handlePageSelect = (page) => {
        setActiveSelection('page');
        handleSelection('page', {
            urn: page.urn,
            name: page.name,
            profile_image: page.profile_image,
            accessToken: page.accessToken
        });
    };

    return (
        <>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <TailSpin color="#d3040c" height={50} width={50} />
                </div>
            ) : (

                <div id='callbacks'>
                    <img src={QS} alt="Logo" style={{ height: 30, width: 'auto', marginLeft: '12rem' }} />
                    <div style={{ textAlign: 'center', marginTop: '1rem', fontSize: '20px', fontWeight: 'bold' }}>
                        Add a LinkedIn Profile or a LinkedIn Company Page
                    </div>
                    <div style={{ color: '#d3040c', marginTop: '1rem', fontWeight: 'bold' }}>
                        Add a LinkedIn Profile:
                    </div>
                    <div onClick={handleProfileClick} style={{
                        marginTop: '1rem', display: 'flex', alignItems: 'center', padding: '10px', borderRadius: '8px', border: '1px solid #ddd', cursor: 'pointer'
                    }}>
                        <input type="radio" checked={activeSelection === 'profile'} onChange={() => setActiveSelection('profile')} style={{ marginRight: '10px' }} />
                        {linkedInUserName && linkedInProfilePic && (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <img src={linkedInProfilePic} alt="Profile" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                <div>
                                    <h4 style={{ margin: 0 }}>{linkedInUserName}</h4>
                                </div>
                            </div>
                        )}
                    </div>
                    {Array.isArray(pages) && pages.length > 0 && (
                        <>
                            <div style={{ color: '#d3040c', marginTop: '2rem', fontWeight: 'bold' }}>
                                Add a {linkedInUserName} page associated with this account:
                            </div>
                            <ul style={{ paddingLeft: '0', listStyleType: 'none', marginTop: '1rem' }}>
                                {pages.map((page) => (
                                    <li key={page.urn} style={{
                                        padding: '10px', border: '1px solid #ddd', borderRadius: '8px', marginTop: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer'
                                    }} onClick={() => handlePageSelect(page)}>
                                        <input type="radio" checked={activeSelection === page.urn} onChange={() => setActiveSelection(page.urn)} style={{ marginRight: '10px' }} />
                                        <img src={page.profile_image || 'https://via.placeholder.com/50'} alt={page.name} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                        <div style={{ marginLeft: '10px' }}>
                                            <h4 style={{ margin: '0' }}>{page.name}</h4>
                                            <span style={{ fontSize: '14px' }}>LinkedIn Company Page</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>

            )}
        </>
    );
};

export default LinkedInCallback;